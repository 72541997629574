import React, { useState } from "react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "components/Layout/Layout";
import SupportLandingSection from "components/SupportLanding/SupportLandingSection";
import ContactUsContents from "components/ContactUsContents/ContactUsContents";
import SupportForm from "components/SupportForm/SupportForm";
import ButtonNav from "components/Design/Button/NavButton";
import Icon from "components/CommonIcon/Icon";
import { CRUISE_INQUIRY } from "store/constants/support";
import { KOMODO } from "store/constants/hotel";
import PageContext from "contexts/PageContext";
import ReachUs from "components/ReachUs/ReachUs";
import { generateSupportURL } from "utils/generateSupportURL";
import useQueryParams from "hooks/useQueryParams";
import { WECHAT_LINK } from "store/constants/link";
import { whatsAppUrl } from "utils/whatsAppUrl";
import { transformObject } from "utils/transformv4";

const Support = ({ data, location }) => {
  const { reason, destination } = useQueryParams();
  const { language } = useI18next();
  const { t } = useTranslation(["homepage", "support"]);
  const [supportReason, setSupportReason] = useState(reason);
  const [supportDestination, setSupportDestination] = useState(destination);
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    sections: [destinations],
    hotelsByLocale: [hotel],
    page: [{ seo_tags }],
    activitiesByLocale: allActivities,
    supportDrawer: [supportDrawer],
    supportRecipientEmails: recipientEmail,
  } = transformObject(cms);

  const dataContact = {
    url: generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY),
    label: t("Contact Us"),
  };

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  const reachUsLink =
    language === "zh"
      ? WECHAT_LINK
      : whatsAppUrl(hotel.whatsapp_contact, hotel.custom_whatsapp_message);

  return (
    <PageContext.Provider
      value={{
        showBannerPadding: true,
        reason: supportReason,
        destination: supportDestination,
      }}>
      <Layout
        destination={t("Komodo")}
        seoTitle={t("Ayana Support")}
        seoTags={seo_tags}
        destinationCards={destinations.images}
        navigations={navigations}
        hotel={hotel}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Lako di'a")}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}
        supportDrawer={supportDrawerData}>
        <SupportLandingSection
          title={t("Ayana Support")}
          contactUsContents={<ContactUsContents hotels={hotel.offices} />}
          otherContents={
            <>
              <ReachUs supportReachUsLink={reachUsLink} />
              <ButtonNav
                href="/labuan-bajo/lakodia/faq"
                prefixIcon={<Icon name="comment" />}
                label={t("FAQ")}
              />
            </>
          }
          supportForm={
            <SupportForm
              reason={reason}
              hotels={hotel}
              setReason={setSupportReason}
              destination={destination}
              setDestination={setSupportDestination}
              navigateBackTo="/labuan-bajo/lakodia/thank-you"
              activities={allActivities}
              location={KOMODO}
              recipients={recipientEmail}
            />
          }
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query LakodiaSupport($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      supportDrawer: sections(filters: { name: { eq: "LKD_Support_Drawer" } }) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { id: 5 }) {
        data {
          id
          attributes {
            title
            email
            phone
            name
            whatsapp_contact
            location
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            ...CmsHotelLogo_v4
          }
        }
      }
      page: pagesByLocale(language: $language, where: { name: "LKD_Support" }) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
          }
        }
      }
      activitiesByLocale(language: $language, where: { kind: "BOAT_TRIP" }) {
        data {
          id
          attributes {
            title
            title_en
          }
        }
      }
      sections(filters: { name: { eq: "LKD_Footer_Destination" } }) {
        ...NavSections
      }
      supportRecipientEmails(filters: { location: { eq: "KOMODO" } }) {
        data {
          id
          attributes {
            reason
            location
            emails {
              id
              language
              email
              hotel {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default Support;
